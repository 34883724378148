import React, { useEffect, useState } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { StaticImage } from 'gatsby-plugin-image'
import bannerLeft from '../static/images/getab/banner-l.png'
import bannerRight from '../static/images/getab/banner-r.png'
import { numFormat } from '../utils/createTime'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { getRequest } from '../utils/axiosData'
import { getNewUrl } from '../utils/url'
import btse from "../static/images/getab/btse.png";
import bitget from "../static/images/getab/bitget.png"
import htx from "../static/images/getab/htx.png"
import mexc from "../static/images/getab/mexc.png"
import gate from "../static/images/getab/gate.png"
import bingx from "../static/images/getab/bingx.png"
import bitmart from "../static/images/getab/bitmart.png"
import lbank from "../static/images/getab/lbank.png"
import hotcoin from "../static/images/getab/hotcoin.png"
import ueex from "../static/images/getab/ueex.png"
import eeee from "../static/images/getab/4e.png"
import biconomy from "../static/images/getab/biconomy.png"
import weex from "../static/images/getab/weex.png"
import digifinex from "../static/images/getab/digifinex.png"

export default GetAB

function GetAB() {
  let pageModel = new PageModelTitle('Get AB', 'getab-page')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const [data, setData]: any = useState([])
  const [balance, setBalance]: any = useState([])

  useEffect(() => {
    const totalUrl = getNewUrl + '/api/v1/brief'
    const balanceUrl = getNewUrl + '/api/v1/addr/NEW182E111111111111111111111111114FhDeS/'
    const fetchData = async () => {
      const res = await getRequest(totalUrl)
      setData(res.data)
    }
    fetchData()
    const balanceData = async () => {
      const res = await getRequest(balanceUrl)
      setBalance(res.data.balance)
    }
    balanceData()
  }, [])

  const currentLanguage = intl.locale
  let bridgeUrl = ''
  let learnMoreUrl = ''
  if (currentLanguage == 'zh') {
    bridgeUrl = 'https://newbridge.network/zh/'
    learnMoreUrl = 'https://newbridge.network/zh/docs/assets/'
  } else {
    bridgeUrl = 'https://newbridge.network/'
    learnMoreUrl = 'https://newbridge.network/docs/assets/'
  }

  return (
    <div id={'getab'}>
      <div className={'banner-pc container'}>
        <img className={'banner-left'} src={bannerLeft} alt="img" />
        <h2>
          <span className='text-blue2B'>{intl.formatMessage({ id: 'G E T' })}</span>{intl.formatMessage({ id: 'A B' })}
        </h2>
        <img className={'banner-right'} src={bannerRight} alt="img" />
      </div>
      <div className='banner-h5 container'>
        <h2>
          <span className='text-blue2B'>{intl.formatMessage({ id: 'G E T' })}</span>{intl.formatMessage({ id: 'A B' })}
        </h2>
        <div>
          <img className={'banner-left'} src={bannerLeft} alt="img" />
          <img className={'banner-right'} src={bannerRight} alt="img" />
        </div>
      </div>
      <div className={'getab-content container'}>
        <div className={'newtax-total'}>
          <dl className={'total-top'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon1.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'AB Total Amount' })}</h3>
              <span>{intl.formatMessage({ id: '100 Billion' })} AB</span>
            </dd>
          </dl>
          <dl className={'total-top'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon2.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'Destroyed' })}</h3>
              <span>{balance !== '' ? numFormat(balance) : null} AB</span>
            </dd>
          </dl>
          <dl className={'total-top'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon3.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'Circulating Supply' })}</h3>
              <span>{data.circulating_supply !== '' ? numFormat(data.circulating_supply) : null} AB</span>
            </dd>
          </dl>
          <dl className={'total-bottom'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon4.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'Price of the Day' })}</h3>
              <span>{data.newton_price_usd !== '' ? data.newton_price_usd : null} USD</span>
            </dd>
          </dl>
          <dl className={'total-bottom'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon5.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'Stake Total Amount' })}</h3>
              <span>{data.locked_amount !== '' ? numFormat(data.locked_amount) : null} AB</span>
            </dd>
          </dl>
          <dl className={'total-bottom'}>
            <dt>
              <StaticImage placeholder="blurred" alt="img" src="../static/images/getab/icon6.png" />
            </dt>
            <dd>
              <h3>{intl.formatMessage({ id: 'Released on the Day' })}</h3>
              <span>{data.incentive_release !== '' ? numFormat(data.incentive_release) : null} AB</span>
            </dd>
          </dl>
        </div>
        <div className={'exchange'}>
          <h2>{intl.formatMessage({ id: 'Listed Exchange' })}</h2>
          <div className={'top-img'}>
            <a href="https://bitget.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={bitget} />
            </a>
            <a href="https://www.htx.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={htx} />
            </a>
            <a href="https://www.mexc.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={mexc} />
            </a>
            <a href="https://www.gate.io/signup?ch=signupAB" target="_blank" className={'mexc'}>
              <img alt="img" src={gate} />
            </a>
            <a href="https://bingx.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={bingx} />
            </a>
            <a href="https://bitmart.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={bitmart} />
            </a>
            <a href="https://digifinex.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={digifinex} />
            </a>
            <a href="https://lbank.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={lbank} />
            </a>
            <a href="https://www.hotcoin.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={hotcoin} />
            </a>
            <a href="https://ueex.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={ueex} />
            </a>
            <a href="https://weex.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={weex} />
            </a>
            <a href="https://www.eeee.com" target="_blank" className={'mexc'}>
              <img alt="img" src={eeee} />
            </a>
          </div>
        </div>
        <div className={'exchange upcoming'}>
          <h2>{intl.formatMessage({ id: 'Upcoming Exchange' })}</h2>
          <div className={'top-img'}>
            <a href="https://biconomy.com/" target="_blank" className={'mexc'}>
              <img alt="img" src={biconomy} />
            </a>
            <a href="https://btse.com/" target="_blank" className={'btse'}>
              <img alt="img" src={ btse } />
            </a>
          </div>
        </div>
        
        {/* <div className={'dex'}>
          <h2>{intl.formatMessage({ id: 'DEX' })}</h2>
          <div className={'dex-a'}>
            <a href="https://newswap.org/" target="_blank">
              <StaticImage alt="img" src="../static/images/ecosystem/new-swap.png" />
            </a>
          </div>
        </div> */}
        <div className={'wallet'}>
          <h2>{intl.formatMessage({ id: 'Wallet' })}</h2>
          <div className='text-center'>
            <Link to="/abwallet/">
              <StaticImage alt="img" src="../static/images/getab/ab-wallet.png" />
            </Link>
            {/* <a
              href="https://addons.mozilla.org/zh-CN/firefox/addon/newmask/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
              target="_blank"
              className={'new-mask'}
            >
              <StaticImage alt="img" src="../static/images/ecosystem/new-mask.png" />
            </a> */}
          </div>
        </div>
        {/* <div className={'dex Bridge'}>
          <h2>{intl.formatMessage({ id: 'Bridge' })}</h2>
          <div>
            <a
              href={ bridgeUrl }
              target="_blank"
              className={'new-bridge'}
            >
              <StaticImage alt="img" src="../static/images/ecosystem/new-bridge.png" />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  )
}